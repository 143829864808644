<template>
  <div>
    <Form ref="form" slot="main" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="120" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="排序" prop="index">
        <Input v-model="form.index" placeholder="排序"></Input>
      </FormItem>
      <FormItem label="所属项目类型" prop="projectCategory">
        <Select v-model="form.projectCategory" placeholder="所属项目类型">
          <Option v-for="(item,index) in baseData['项目类型']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem v-for="(item, index) in form.item" :key="index" :label="index === 0 ? '检查资料' : ''" :prop="`item[${index}].name`" style="width: 100%">
        <Row>
          <Col span="22">
            <Input type="text" v-model="item.name" placeholder="检查资料"></Input>
          </Col>
          <Col span="2">
            <Button :disabled="form.item.length === 1" icon="ios-remove" @click="handleRemove(index)" style="width: 100%;" type="primary"></Button>
          </Col>
        </Row>
      </FormItem>
      <FormItem>
        <Button icon="ios-add" @click="addInspection"  type="primary">增加资料</Button>
      </FormItem>
      <FormItem label="备注" prop="desc" style="width: 100%">
        <Input v-model="form.desc" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 3,maxRows: 5}" placeholder="备注"></Input>
      </FormItem>
      <FormItem label="快捷录入" prop="tag" style="width: 100%">
        <Input v-model="form.tag" placeholder="英文分号分隔"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea" slot="bottomBtn" ref="bottomBtn">
      <Button type="primary" @click="ok('/doc/category/Add')">保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {docCategoryInfo} from "@/api/InspectionData";

export default {
  name: 'projectEdit',
  mixins: [edit],

  data() {
    return {
      ruleValidate: {},
      form: {
        id: '',
        index: '',
        projectCategory: '',
        item: [{
          name: ''
        }],
        desc: '',
        tag: '',
      },
    }
  },
  mounted() {
    if (this.mid) {
      docCategoryInfo({id: this.mid}).then(res => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        });
      })
      return false;
    }
  },
  methods: {
    addInspection() {
      this.form.item.push({
        name: ""
      })
    },
    handleRemove(index) {
      this.form.item.splice(index, 1);
    },
  }
}
</script>

<style lang="less" scoped>
</style>
