import {get} from 'ys-admin'


/**
 * 检查资料详情
 * @param {Object} params
 * @returns {Object}
 */
export const docCategoryInfo = function (params) {
  return get('/doc/category/Info', params)
};